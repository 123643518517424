<template>
  <div class="ui-orders__wrapper-3">
    <aside class="ui-orders__sidebar">
      <div class="overflow-hidden">
        <CRow>
          <CCol col="auto">
            <h3>{{ $t("role.orders.rounds.ddmi.title") }}</h3>
          </CCol>
          <CCol col="auto">
            <OrdersLegend :statusMap="statusMap" />
          </CCol>
        </CRow>

        <CRow class="justify-content-between">
          <CCol col="auto">
            <CButton
              color="primary"
              @click="onAllDelivered"
              :disabled="!canView(PERMS.ORDERS_EDIT)"
              >{{ $t("role.orders.rounds.ddmi.allDeliveredBtn") }}</CButton
            >
          </CCol>
          <CCol col="auto">
            <OrdersPrints
              class="mb-3"
              :prints="round.prints"
              :types="['wh_islands']"
              :title="$t('role.orders.rounds.ddmi.printLabelsBtn')"
            />
          </CCol>
        </CRow>
      </div>

      <div class="d-flex justify-content-center" v-if="loading">
        <CSpinner color="info" />
      </div>
      <div v-else>
        <CCard
          class="card-selectable mb-2"
          v-for="island in islands"
          :key="island.id"
          :class="cardClasses(island)"
        >
          <CCardBody @click.prevent="selectIsland(island)" class="p-2">
            <CRow class="justify-content-between align-items-center mb-1">
              <CCol>
                <div class="text-truncate">
                  I{{ islandId(island) }}
                  <strong>{{ islandName(island) }}</strong>
                </div>
              </CCol>
              <CCol col="auto">
                <div class="d-flex">
                  <span class="mr-2">
                    {{ $t("role.orders.rounds.ddmi.from") }}
                    {{ islandDeliveryDate(island, "dd-MM") }}
                    {{ $t("role.orders.rounds.ddmi.to") }}
                    {{ islandDeliveryDate(island, "HH:mm") }}
                  </span>
                  <dropdown-menu
                    :items="actions"
                    :select="(e) => onActionSelect(e, island)"
                    @click.native.stop
                    ><font-awesome-icon icon="ellipsis-h" />
                  </dropdown-menu>
                </div>
              </CCol>
            </CRow>
            <CProgress>
              <CProgressBar
                v-for="progress in ddmiProgress(island)"
                :key="progress.id"
                :color="`orders-${progress.color}`"
                :value="progress.percentage"
                >{{ progress.value }}</CProgressBar
              >
            </CProgress>
            <CRow class="justify-content-between">
              <CCol col="auto">
                {{ islandProdsCount(island) }}
                {{ $t("role.orders.rounds.ddmi.articles") }}
                -
                {{ islandOrdersCount(island) }}
                {{ $t("role.orders.rounds.ddmi.orders") }}
                <OrderIncidentBadge
                  class="ml-2"
                  :total="island.incidents"
                  :open="island.incidents_open"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard class="mb-2" v-if="!islands.length && !loading">
          <CCardBody> {{ $t("role.orders.rounds.ddmp.noResults") }} </CCardBody>
        </CCard>
      </div>
    </aside>
    <main class="ui-orders__main">
      <Detail
        v-if="islandActive"
        :island="islandActive"
        :parentIsLoading="loading"
      />
    </main>
  </div>
</template>

<script>
import { Query } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../../../ds";
import Detail from "./Detail";
import OrdersPrints from "../../../share/OrdersPrints";
import OrdersLegend from "../../../share/OrdersLegend";
import OrderIncidentBadge from "../../../share/OrderIncidentBadge";
import orderManagementMixin from "../../../../../../mixins/orderManagement";
import {
  ddmiProgressMap,
  INCIDENT_ENTITY_TYPE,
  INCIDENT_TYPE,
  ORDER_ACTION,
} from "../../../../../../config/global";
import EventBus from "../../../../../../helpers/EventBus";
import { mapGetters, mapState } from "vuex";
import { buildPredicate } from "../../../../../../helpers/common";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";

export default {
  name: "ViewDdmi",

  mixins: [orderManagementMixin],

  components: {
    Detail,
    OrdersPrints,
    OrdersLegend,
    OrderIncidentBadge,
  },

  data() {
    const canEdit = this.canView(PERMS.ORDERS_EDIT);
    return {
      PERMS,
      loading: false,
      islands: [],
      islandActive: null,
      warehouseOptions: [],
      actions: [
        {
          id: ORDER_ACTION.FILTER,
          text: this.$t("role.orders.rounds.ddmi.actionLabels.filter"),
        },
        {
          id: ORDER_ACTION.DONE,
          text: this.$t("role.orders.rounds.ddmi.actionLabels.allDelivered"),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.UNDONE,
          text: this.$t("role.orders.rounds.wh.actionLabels.allNotDelivered"),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.INCIDENT,
          text: this.$t("role.orders.rounds.wh.actionLabels.openIncident"),
          disabled: !canEdit,
        },

        {
          id: ORDER_ACTION.CANCELLATION,
          text: this.$t("role.orders.rounds.ddmi.actionLabels.cancelAll"),
          disabled: !canEdit,
        },
      ],
    };
  },

  watch: {
    round() {
      this.init();
    },
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),

    ...mapState("rounds", ["round"]),

    statusMap() {
      return ddmiProgressMap(this.$i18n);
    },
  },

  mounted() {
    this.init();
    EventBus.$on("orders:refresh", this.init);
    EventBus.$on("orders:applyFilters", this.init);
    EventBus.$on("orders:priceUpdate", this.onPriceUpdate);
  },

  beforeDestroy() {
    EventBus.$off("orders:refresh", this.init);
    EventBus.$off("orders:applyFilters", this.init);
    EventBus.$off("orders:priceUpdate", this.onPriceUpdate);
  },

  methods: {
    init() {
      this.getIslands();
    },

    getFilterQuery() {
      const { keyword } = this.$store.state.rounds.filters;

      const map = filtersMap("ddmi.main.filters");

      let predicate = buildPredicate(
        null,
        map,
        this.$store.state.rounds.filters
      );

      let query = predicate ? new Query().where(predicate) : new Query();

      const searchFields = filtersMap("ddmi.main.search", []);
      if (keyword && searchFields.length > 0) {
        query = query.search(keyword, searchFields);
      }

      return query;
    },

    getIslands() {
      const self = this;
      const roleId = this.$store.state.role.id;
      const roundId = this.$store.state.rounds.round.id;
      const endpoint = filtersMap("ddmi.main.endpoint");
      const dm = GetDataManagerNew(endpoint, [roleId, roundId]);
      const query = this.getFilterQuery();
      self.loading = true;
      dm.executeQuery(query)
        .then((response) => {
          self.islands = response.result;
          self.loading = false;

          let selected = null;

          if (self.islandActive) {
            selected =
              self.islands.find((item) => item.id === self.islandActive.id) ||
              null;
          }

          if (selected === null && self.islands.length > 0) {
            selected = self.islands[0];
          }

          self.selectIsland(selected);
        })
        .catch((response) => {
          console.error(response);
          self.$store.dispatch("toaster/add", {
            title: $t("common.editor.messages.ko.title"),
            text: $t("common.general.loadingError"),
            color: "info",
            autohide: true,
          });
          self.loading = false;
        });
    },

    selectIsland(island) {
      this.islandActive = island;
    },

    cardClasses(record) {
      const isSelected =
        this.islandActive && this.islandActive.id === record.id;
      const haveError = this.producerDeliveryDateWarning(record);
      return { "card-selected": isSelected, "card-error": haveError };
    },

    onActionSelect(args, record) {
      const ddmis = [record.id];

      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };

      if (args.item.id === ORDER_ACTION.FILTER) {
        const param = { id: record.ddmi.id, label: this.islandName(record) };
        this.$store.commit("rounds/updateIslandId", param);
        this.init();
      }

      if (args.item.id === ORDER_ACTION.DONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("ddmi.statusCode.next"),
            ddmis,
          })
          .then(onSuccess);
      }

      if (args.item.id === ORDER_ACTION.UNDONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("ddmi.statusCode.prev"),
            ddmis,
          })
          .then(onSuccess);
      }

      if (args.item.id === ORDER_ACTION.INCIDENT) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [{ type: INCIDENT_ENTITY_TYPE.DDMIS, items: [record] }],
          type: INCIDENT_TYPE.INCIDENT,
          show: true,
        });
      }

      if (args.item.id === ORDER_ACTION.CANCELLATION) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [{ type: INCIDENT_ENTITY_TYPE.DDMIS, items: [record] }],
          type: INCIDENT_TYPE.CANCELLATION,
          show: true,
        });
      }
    },

    onAllDelivered() {
      const ddmis = this.islands.map((item) => item.id);
      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };
      this.$store
        .dispatch("rounds/updateStatusBatch", {
          status: filtersMap("ddmi.statusCode.next"),
          ddmis,
        })
        .then(onSuccess);
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },

    onPriceUpdate() {
      this.init();
    },
  },
};
</script>
