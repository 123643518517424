<template>
  <div>
    <div class="d-flex justify-content-center" v-if="parentIsLoading">
      <CSpinner color="info" />
    </div>
    <div v-else>
      <div class="overflow-hidden">
        <CRow>
          <CCol>
            <div class="ui-type-caption text-uppercase text-gray-600">
              {{ $t("role.orders.rounds.ddmi.details.title") }}
            </div>
            <div class="ui-type-display-sm">{{ islandName(island) }}</div>
            <div class="ui-type-body mb-2">
              <OrdersLocationMap
                :title="islandFamilyPositionName(island)"
                :position="islandFamilyPosition(island)"
              />
              <OrdersContacts
                class="ml-3"
                :title="$t('role.orders.contacts.title')"
                :contacts="islandContacts(island)"
              />
            </div>
          </CCol>
        </CRow>

        <CRow class="justify-content-between mb-3">
          <CCol col="auto">
            <font-awesome-icon icon="warehouse" />
            {{ $t("role.orders.rounds.ddmi.details.toDeliverFrom") }}
            <strong>
              {{ islandDeliveryDate(island, "dd-MM") }}
              {{ $t("role.orders.rounds.ddmi.to") }}
              {{ islandDeliveryDate(island, "HH:mm") }}</strong
            >
          </CCol>
          <CCol col="auto">
            <CButton
              color="primary"
              @click.prevent="allDelivered"
              :disabled="!canView(PERMS.ORDERS_EDIT)"
              >{{ $t("role.orders.rounds.ddmi.allDeliveredBtn") }}</CButton
            >
          </CCol>
        </CRow>
      </div>

      <CCard class="mb-2">
        <CCardBody>
          <OrdersTable :island="island" />
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import orderManagementMixin from "../../../../../../mixins/orderManagement";
import OrdersTable from "./OrdersTable";
import OrdersContacts from "../../../share/OrdersContacts";
import OrdersLocationMap from "../../../share/OrdersLocationMap";
import EventBus from "../../../../../../helpers/EventBus";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";

export default {
  name: "Detail",

  components: {
    OrdersTable,
    OrdersContacts,
    OrdersLocationMap,
  },

  mixins: [orderManagementMixin],

  props: {
    island: { type: Object, required: true },
    parentIsLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      PERMS,
    };
  },

  methods: {
    allDelivered() {
      const ddmis = [this.island.id];
      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };
      this.$store
        .dispatch("rounds/updateStatusBatch", {
          status: filtersMap("ddmi.statusCode.next"),
          ddmis,
        })
        .then(onSuccess);
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
  },
};
</script>
